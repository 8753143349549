export function pushHistory(path: string) {
  let history: string[] = JSON.parse(localStorage.getItem('HISTORY') || '[]');
  const prev = history[history.length - 1];
  if (prev && prev.split('?')[0] === path.split('?')[0]) {
    history = history.slice(0, -1);
  }
  if (history.length > 30) {
    history = history.slice(history.length - 30);
  }
  localStorage.setItem('HISTORY', JSON.stringify([...history, path]));
}

export function getHistory(): string[] {
  return JSON.parse(localStorage.getItem('HISTORY') || '[]');
}

export function clearHistory() {
  localStorage.removeItem('HISTORY');
}

// 保存用户来源
export function setUserSource(data: { source: string; username?: string }) {
  localStorage.setItem('USER_SOURCE', JSON.stringify(data));
}

export function getUserSource(): { source: string; username?: string } {
  return JSON.parse(localStorage.getItem('USER_SOURCE') ?? '{}');
}

export function clearUserSource() {
  localStorage.removeItem('USER_SOURCE');
}

// 记录登录用户token
type LoginCache = {
  loginToken: string;
  username: string;
};
export function getLoginToken(): LoginCache {
  return JSON.parse(localStorage.getItem('LOGIN_CACHE') ?? '{}');
}

export function setLoginToken(cache: LoginCache) {
  localStorage.setItem('LOGIN_CACHE', JSON.stringify(cache));
}

export function clearLoginToken() {
  localStorage.removeItem('LOGIN_CACHE');
}

// 记录新用户注册完成后重定向页面
export function getNewUserRedirectTo() {
  return localStorage.getItem('NEW_USER_REDIRECT_TO') ?? '';
}

export function setNewUserRedirectTo(url: string) {
  localStorage.setItem('NEW_USER_REDIRECT_TO', url);
}

export function clearNewUserRedirectTo() {
  localStorage.removeItem('NEW_USER_REDIRECT_TO');
}

// 记录来源网站地址
export function getReferrerURL() {
  return localStorage.getItem('REFERRER_URL');
}

export function setReferrerURL() {
  const referrer = document.referrer ?? '';
  if (referrer && !referrer.startsWith(location.origin)) {
    localStorage.setItem('REFERRER_URL', referrer);
  }
}
