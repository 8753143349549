import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { gaMiddleware } from './middleware';

export default createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
        applyMiddleware(gaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose(applyMiddleware(gaMiddleware))
);
