import { adjust } from 'ramda';
type State = {
  list: ProjectResp[];
  current: string;
};

const getNewList = (list: any, isDefault: boolean) => {
  let newList;
  if (isDefault) {
    newList = list.map((p: any) => ({ ...p, default: false }));
  } else {
    newList = list;
  }
  return newList;
};

export default function (
  state: State = {
    list: [],
    current: '',
  } as State,
  { type, payload }: ReduxAction<any>
) {
  let newList;
  switch (type) {
    case 'SET_PROJECTS':
      return {
        ...state,
        list: Array.isArray(payload) ? payload : [],
      };
    case 'SET_CURRENT_PROJECT':
      return { ...state, current: payload };
    case 'UPDATE_PROJECT':
      newList = getNewList(state.list, payload.default);
      const idx = state.list.findIndex(({ uid }) => uid === payload.uid);
      return {
        ...state,
        list: adjust(idx, () => payload, newList),
      };
    case 'CREATE_PROJECT':
      newList = getNewList(state.list, payload.default);
      return { ...state, list: newList.concat(payload) };
    case 'LOGOUT':
      return {};

    default:
      return state;
  }
}
