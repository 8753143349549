import { getPartnerCode } from '@zenlayer/portal-utils';
import posthog from 'posthog-js';
import { parse } from 'query-string';

export const posthogUtil = {
  async init(
    api_key: string,
    options?: Parameters<(typeof posthog)['init']>[1],
    otherOptions?: {
      forceReset?: boolean;
    }
  ) {
    if (otherOptions?.forceReset) {
      await posthog.reset();
    }

    // 在生产环境引入
    await posthog.init(api_key, {
      api_host: 'https://app.posthog.com',
      loaded: (posthog) => {
        const search = parse(location.search);
        if (search.promotion_tag) {
          posthog.people.set({
            promotion_tag: search.promotion_tag,
            partner: getPartnerCode(),
          });
        }
      },
      persistence: 'localStorage+cookie',
      enable_heatmaps: true,
      enable_recording_console_log: true,
      ...options,
    });
  },
  async reset() {
    console.log('post hog reset');
    return await posthog.reset();
  },
};
