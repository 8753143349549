import type { CreateTicketParams } from '@/api/ticket';
export interface ContactModalVisible {
  visible: boolean;
  defaultData?: Partial<CreateTicketParams>;
  onActionAfter?: () => void;
}
export default function (
  state: ContactModalVisible = {} as ContactModalVisible,
  { type, payload }: ReduxAction<ContactModalVisible>
) {
  switch (type) {
    case 'SET_CONTACTUS_VISIBLE':
      return payload.visible
        ? payload
        : {
            visible: false,
          };
    default:
      return { visible: false };
  }
}
