export default function (
  state: TeamProductResp[] = [],
  { type, payload }: ReduxAction<TeamProductResp>
) {
  switch (type) {
    case 'SET_TEAM_PRODUCTS':
      return Array.isArray(payload) ? payload : [];

    default:
      return state;
  }
}
