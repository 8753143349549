import { clearLoginToken } from '@/utils/session';
import { removeAllFetchCache } from '@zenlayer/portal-utils';

const gaTriggerMap = {
  SET_USER: (payload) => {
    const { firstName, lastName, username, source } = payload;
  },
  LOGOUT: (payload) => {
    clearLoginToken();
    removeAllFetchCache();
  },
};
export const gaMiddleware = (store) => (next) => (action) => {
  const trigger = gaTriggerMap[action.type];
  if (trigger) {
    trigger(action.payload);
  }
  return next(action);
};
