export default function (
  state: RechargeModalVisible = {} as RechargeModalVisible,
  { type, payload }: ReduxAction<RechargeModalVisible>
) {
  switch (type) {
    case 'SET_RECHARGE_VISIBLE':
      return payload.visible
        ? payload
        : {
            visible: false,
          };
    default:
      return { visible: false };
  }
}
