/**
 * 通用请求函数
 */
import { getPartnerCode } from '@/config';
import { encrypt_aes } from '@zenlayer/portal-utils';
import {
  zenFetch,
  ZenFetchRequestConfig,
  zenSigleton,
  defaultKeyGenerator,
  getDisableFetchCache,
} from '@zenlayer/portal-utils';
import { Modal } from '@zenlayer/zen-design';
import getSubdomain from '@zenlayer/zen-design/esm/_util/getSubdomain';
import { addXmlhttprequestData } from '@zenlayer/zen-performance';

let respInterceptor: number;
let reqInterceptor: number;

//注册响应拦截器
export const registerRespInterceptor = (onSuccess: Function) => {
  respInterceptor = zenFetch.interceptors.response.use(function (response) {
    if (onSuccess) {
      return onSuccess(response);
    }
  });
};

// 卸载响应拦截器
export const unregisterRespInterceptor = () => {
  if (reqInterceptor) {
    zenFetch.interceptors.response.eject(respInterceptor);
  }
};

zenFetch.interceptors.request.use(
  (config) => {
    if (
      config.withCredentials &&
      config.headers &&
      typeof config.headers.set === 'function'
    ) {
      if (getPartnerCode()) {
        config.headers.set('X-Zenconsole-Partner', getPartnerCode());
      }
      if (getSubdomain()) {
        config.headers.set('X-Zenconsole-Domain', getSubdomain());
      }
      if (zenSigleton.has('csrfToken')) {
        config.headers.set(
          'X-Zenconsole-Token',
          encrypt_aes(zenSigleton.get('csrfToken')!)
        );
      }

      if (config.cache && !config.id) {
        const f = {
          ...config,
          params: {
            ...config.params,
            teamUid:
              zenSigleton.has('teamUid') && zenSigleton.get('teamUid' as any),
            resourceGroupUid:
              zenSigleton.has('resourceGroupUid') &&
              zenSigleton.get('resourceGroupUid'),
          },
        };
        config.id = defaultKeyGenerator(f);
      }
      if (getDisableFetchCache()) {
        config.cache = false;
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

zenFetch.interceptors.response.use(function (response) {
  if (!response.cached) {
    addXmlhttprequestData({
      name: response.request.responseURL,
      headers: response.headers,
    });
  }

  return response;
});

const isAddResourceGroupReq = (url?: string) => {
  const exceptionPrefix = [
    '/api/dashboard/query',
    '/api/dashboard/teams',
    '/api/dashboard/team/',
  ];
  const matchPrefix = [
    '/api/bmc',
    '/api/dashboard',
    '/api/sdn',
    '/api/customerresource',
  ];
  if (exceptionPrefix.some((prefix) => url?.startsWith(prefix))) {
    return false;
  }
  if (!matchPrefix.some((prefix) => url?.startsWith(prefix))) {
    return false;
  }
  return true;
};

export function fetch<T>(params: ZenFetchRequestConfig) {
  if (isAddResourceGroupReq(params.url)) {
    params.headers = {
      ...(params.headers as any),
      'X-Zenconsole-Group-Id': zenSigleton.has('resourceGroupUid')
        ? zenSigleton.get('resourceGroupUid')
        : undefined,
    };
  }
  return zenFetch.request<T>(params).then((res) => {
    if (res.status === 403) {
      Modal.destroyAll();
    }
    return res;
  });
}

export function request<T = any>(params: ZenFetchRequestConfig) {
  return fetch<RequestRes<T>>(params).then((res) => {
    const resD = res?.data ?? <RequestRes<T>>{};
    return {
      ...resD,
      data: resD?.data ?? <T>{},
    };
  });
}

export const requestFactory = function (prefix: string) {
  return function <T>(params: ZenFetchRequestConfig) {
    return request<T>({ ...params, url: prefix + params.url });
  };
};
