import { adjust } from 'ramda';

const processTeamInfo = (team: any): TeamInfo => {
  return {
    ...team,
    //ACTIVE: true,
    ACTIVE: team.partner !== 'solana' || team.companyType !== 'default',
  };
};

export default function (
  state: TeamResp[] = [],
  { type, payload }: ReduxAction<TeamResp>
) {
  switch (type) {
    case 'SET_TEAMS':
      return Array.isArray(payload)
        ? payload.map((item) => processTeamInfo(item))
        : [];
    case 'ADD_TEAM':
      return state.concat(processTeamInfo(payload));
    case 'UPDATE_TEAM':
      return adjust(
        state.findIndex((l: any) => l.uid === payload.uid),
        (team) => processTeamInfo({ ...team, ...payload }),
        state
      );
    case 'LOGOUT':
      return [];
    default:
      return state;
  }
}
