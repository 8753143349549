export default function (state: Obj = {}, { type, payload }: ReduxAction<Obj>) {
  switch (type) {
    case 'SET_TEAM_CONFIG':
      return { ...state, ...payload };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
}
