export default function (
  state: AuthState = {} as AuthState,
  { type, payload }: ReduxAction<any>
) {
  switch (type) {
    case 'SET_AUTH':
      return {
        ...state,
        ...payload,
      };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
}
