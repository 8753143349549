type State = {
  resourceGroups?: any[];
  current?: string;
};

export default function (
  state: State = {
    current: undefined,
  },
  { type, payload }: ReduxAction<any>
) {
  switch (type) {
    case 'SET_CURRENT_RESOURCEGROUP':
      return {
        ...state,
        current: payload,
      };
    case 'SET_RESOURCEGROUPS':
      return {
        ...state,
        resourceGroups: payload,
      };

    default:
      return state;
  }
}
