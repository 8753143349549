import { useEffect } from 'react';
const useNormalizeStylePosition = () => {
  useEffect(() => {
    const normalizeStyle = document.head.querySelector(
      "link[attrname='normalizeStyle']"
    );
    const allLink = document.head.querySelectorAll('link');

    if (allLink[allLink.length - 1] !== normalizeStyle) {
      normalizeStyle && document.head.appendChild(normalizeStyle);
    }
  }, []);
};

export default useNormalizeStylePosition;
