const { public_path } = window._zen_env_ || {};
__webpack_public_path__ = /\/$/.test(public_path || '')
  ? public_path
  : `${public_path || ''}/`;

import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import {
  initThemes,
  addSysThemeChangeListener,
} from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';

import { getThemes } from '@/config';
import { addAllBrowserTabsLocationListener } from '@zenlayer/portal-utils';
import { getLocale, addLocaleEventListener } from '@zenlayer/zen-intl18';
import zhLocale from '@zenlayer/zen-design/esm/locale/zh';
import enLocale from '@zenlayer/zen-design/esm/locale/en';
import { setConfig } from '@zenlayer/zen-design/esm/hooks/useConfig';

import zenPerformance from '@zenlayer/zen-performance';

const locale = getLocale();
if (locale === 'en') {
  setConfig({ locale: enLocale, dayjs: { localeName: 'en' } });
} else if (locale === 'zh') {
  setConfig({ locale: zhLocale, dayjs: { localeName: 'zh' } });
}

addLocaleEventListener((newLocale) => {
  if (newLocale !== locale) {
    location.reload();
  }
});

addAllBrowserTabsLocationListener();

initThemes({ themes: getThemes() });
addSysThemeChangeListener();

zenPerformance.init({
  application: 'console',
  sendUrl: '/api/collectApi/collect/web/performance',
});

createRoot(document.getElementById('portal') as Element).render(
  createElement(App)
);
