import { combineReducers } from 'redux';
import user from './user';
import auth from './auth';
import contactus from './contactus';
import team from './team';
import account from './account';
import project from './project';
import recharge from './recharge';
import resourceGroup from './resourceGroup';
import teamConfig from './teamConfig';
import products from './products';

export default combineReducers({
  user,
  auth,
  team,
  account,
  project,
  resourceGroup,
  teamConfig,
  contactus,
  recharge,
  products,
});
