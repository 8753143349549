export default function (
  state: UserResp = {} as UserResp,
  { type, payload }: ReduxAction<any>
) {
  switch (type) {
    case 'SET_USER':
      const result = { ...state, ...payload };
      return {
        ...result,
      };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
}
