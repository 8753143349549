export default function (
  state: AccountResp,
  { type, payload }: ReduxAction<any>
) {
  switch (type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        ...payload,
        balance: parseFloat(payload.balance.toFixed(2)),
      };
    case 'LOGOUT':
      return null;
    default:
      return state || null;
  }
}
